@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.auth-button {
  background: linear-gradient(112.44deg, #ff5858 2.09%, #c058ff 75.22%);
  background-size: auto;
  background-size: 165%;
  transition: 0.2s ease-in-out;
}

.auth-button:hover {
  opacity: 0.85;
}

.grey-border-block {
  background-color: rgba(13, 12, 34, 0.158);
  height: 1px;
}

.admin-navbar .active {
  @apply text-pink-700;
}

.link-display:hover {
  transform: translate3d(0px, 0px, 0px) scale(1.015);
}
